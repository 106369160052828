import React from "react";
// import FeaturedStory from "../../components/blocks/FeaturedStory";
// import LinkList from "../../components/blocks/LinkList";
import Accordion from "../../../components/blocks/Accordion";

const HWBTopInlineContent = ({ entry }) => {
  return entry.contentOptions.map((block, index) => (
    <React.Fragment key={index}>
      {block.__typename === "contentOptions_accordion_BlockType" && (
        <Accordion block={block} />
      )}
    </React.Fragment>
  ));
};

const HWBTopContentBlocks = ({ data }) => {
  return (
    <div className="contentBlocks-container">
      <HWBTopInlineContent entry={data} />
    </div>
  );
};

export default HWBTopContentBlocks;