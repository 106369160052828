import React, { Component } from 'react';
import './technologyMediaInformationLiteracy-Team.css';

export default class TechnologyMediaInformationLiteracyTeam extends Component {
  render() {
    return (
      <div className="technologyMediaInformationLiteracyTeam-container">
        <div className="technologyMediaInformationLiteracyTeam-content-container">
          <h2 className="technologyMediaInformationLiteracyTeam-title">Meet the Team Leading This Work</h2>
          {/* <h2>Current Lab Members</h2> */}
          {/* <h2 className="technologyMediaInformationLiteracyEducationTeam-titleContent-container"><span>Meet the Team Leading This Work</span></h2> */}
          <div class="technologyMediaInformationLiteracyTeam-paragraph">
            {/* <h2>To put it simply, conceptual development means 'learning'</h2> */}
            <p>Today’s youth have been born into, and are growing up in, a digitally-connected world. They are learning, connecting, and expressing themselves with virtual reality, AI, robots, and a range of other emerging hardware and software—often in unprecedented ways. Our team aims to equip young people with the tools and skills to responsibly build and work with emerging technologies, creatively develop digital media, and critically consume information online.</p>
          </div>
          <ul className="technologyMediaInformationLiteracyTeam-cardsList">
            <li>
              <a href="/about/team/riddhi-divanji" className="technologyMediaInformationLiteracyTeam-linkNew">
                <div className="technologyMediaInformationLiteracyTeam-cardNew">
                  <div className="technologyMediaInformationLiteracyTeam-card-imageNew">
                    <img src='https://s3.us-west-2.amazonaws.com/craft-images/Team/Headshots/Team-Headshot-Template-1.png' width="100%" alt="young woman in navy sweater smiling"/>
                  </div>
                  <div className="technologyMediaInformationLiteracyTeam-card-contentNew">   
                    <h2>Riddhi Divanji</h2>
                    <p>Team Lead</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/katharine-chen" className="technologyMediaInformationLiteracyTeam-linkNew">
                <div className="technologyMediaInformationLiteracyTeam-cardNew">
                  <div className="technologyMediaInformationLiteracyTeam-card-imageNew">
                    <img src='https://craft-images.s3.us-west-2.amazonaws.com/Team/Headshots/Staff-Headshot-Kat-Chen-2024.png' width="100%" alt="young woman in black blazer jacket"/>
                  </div>
                  <div className="technologyMediaInformationLiteracyTeam-card-contentNew">   
                    <h2>Katharine Chen</h2>
                    <p>Assistant Researcher</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/tania-dearden" className="technologyMediaInformationLiteracyTeam-linkNew">
                <div className="technologyMediaInformationLiteracyTeam-cardNew">
                  <div className="technologyMediaInformationLiteracyTeam-card-imageNew">
                    <img src='https://f10-people.s3-us-west-2.amazonaws.com/Re-edited+Team+Photos+230x300/Most+Recent/tania.jpg' width="100%" alt="young woman in navy sweater smiling"/>
                  </div>
                  <div className="technologyMediaInformationLiteracyTeam-card-contentNew">   
                    <h2>Tania Dearden</h2>
                    <p>Education Programs Manager</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/jennifer-rubin" className="technologyMediaInformationLiteracyTeam-linkNew">
                <div className="technologyMediaInformationLiteracyTeam-cardNew">
                  <div className="technologyMediaInformationLiteracyTeam-card-imageNew">
                    <img src='https://f10-people.s3-us-west-2.amazonaws.com/Re-edited+Team+Photos+230x300/Most+Recent/jen.jpg' width="100%" alt="young woman in cream shirt smiling"/>
                  </div>
                  <div className="technologyMediaInformationLiteracyTeam-card-contentNew">   
                    <h2>Jennifer Rubin</h2>
                    <p>Senior Researcher</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/naomi-green" className="healthWellBeingTeam-linkNew">
                <div className="healthWellBeingTeam-cardNew">
                  <div className="healthWellBeingTeam-card-imageNew">
                    <img src='https://f10-people.s3.us-west-2.amazonaws.com/Naomi+Green+Headshot.png' width="100%" alt="young woman in jean jacket smiling"/>
                  </div>
                  <div className="healthWellBeingTeam-card-contentNew">   
                    <h2>Naomi Green</h2>
                    <p>Philanthropy Developer</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}