import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CteEdHeader from './cteEd-Header';
import CteEdContent from './cteEd-Content';
import './cteEd.css';

export default class CteEd extends Component {
  render() {
    return(
      <div id="cteEd-container">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <CteEdHeader />
        <CteEdContent />
      </div>
    );
  }
}