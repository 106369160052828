import React, { useCallback, useMemo } from "react";
import { useQuery, gql } from "@apollo/client";

const query = gql`
query GetResource($slug: [String]) {
  entry(slug: $slug) {
    ... on resources_default_Entry {
      title
      breadcrumbTitle
    }
    ... on stories_default_Entry {
      title
      breadcrumbTitle
    }
    ... on programs_default_Entry {
      title
      breadcrumbTitle
    }
    ... on projects_default_Entry {
      title
      breadcrumbTitle
    }
    ... on opportunities_default_Entry {
      title
      breadcrumbTitle
    }
    ... on press_default_Entry {
      title
      breadcrumbTitle
    }
    ... on pages_default_Entry {
      title
    }

  }
}
`;

const Breadcrumbs = ({ variant = '', slug }) => {
  const { data, loading, error } = useQuery(query, {
    variables: {
      slug: { slug },
    }
  });

  const {
    parent,
    parentTitle,
    title,
  } = useMemo(() => {
    if (loading) {
      return { parent: "", parentTitle: "", title: "" };
    }

    // Set data from the page url.
    const segment_str = window.location.pathname;
    const segment_array = segment_str.split( '/' );

// console.log('segment string:',segment_str);
// console.log('segment array:', segment_array);

    if (!error && !!data?.entry?.breadcrumbTitle) {
      return {
        parent: segment_array?.[1],
        parentTitle: segment_array?.[1]?.replace(/-/g, ' '),
        title: data?.entry?.breadcrumbTitle,
      };
    }
    
    return {
        parent: segment_array?.[1],
        parentTitle: segment_array?.[1]?.replace(/-/g, ' '),
        title: data?.entry?.title,
        // title: segment_array.pop()?.replaceAll("-", " "),
    };
  }, [data, loading, error]);

  const Cheveron = useCallback(() => {
    return (
      <p className="chevron">
        <i className="fa-solid fa-angle-right"></i>
      </p>
    );
  }, []);

  const shareThisStyle = useMemo(
    () => ({
      paddingLeft: "20px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    }),
    []
  );

  return (
    <section className={`globalBreadcrumbs ${variant}`}>
      <div className="container">
        <div className="breadcrumbs-menus">
          <a href="/">
            <i className="fa-solid fa-house" />
          </a>
          {parent && (
            <span>
              <Cheveron />
              <p className="text-medium_bold">
                <a href={"/" + parent}>{parentTitle}</a>
              </p>
            </span>
          )}
          {title && (
            <span style={{ overflow: "hidden" }}>
              <Cheveron />
              <h4 className="text-medium_bold">{title}</h4>
            </span>
          )}
        </div>
        <div className="share">
          <a
            className="text-half-step_bold st-custom-button"
            data-network="sharethis"
            style={shareThisStyle}
          >
            Share This
            <img
              src="/shareIcon.svg"
              alt="Share This"
              style={{ paddingLeft: "4px" }}
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumbs;
