import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import VrLandingPageHeader from './vrLandingPage-Header';
import VrLandingPageContent from './vrLandingPage-Content';
import './vrLandingPage.css';

export default class VrLandingPage extends Component {
  render() {
    return(
      <div id="vrLandingPage-container">
        <Helmet>
          <title>foundry10 Virtual Reality Game Reviews for Classroom Application</title>
          <meta
            name="title"
            content="foundry10 Virtual Reality Game Reviews for Classroom Application"
          />
          <meta
            name="description"
            content="Explore the potential of Virtual and Augmented Reality in classrooms. Discover reviewed VR titles for Art, Math, Science, and more, curated by foundry10."
          />
          <meta property="og:title" content="foundry10 Virtual Reality Game Reviews for Classroom Application" />
          <meta
            property="og:description"
            content="Explore the potential of Virtual and Augmented Reality in classrooms. Discover reviewed VR titles for Art, Math, Science, and more, curated by foundry10."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/vr-game-review" />
          <link rel="canonical" href="https://www.foundry10.org/vr-game-review" />

          {/* Prevents search engine crawlers from indexing page */}
          <meta name="robots" content="noindex" />
          
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Virtual Reality Game Reviews for Classroom Application",
                "description": "Explore the potential of Virtual and Augmented Reality in classrooms. Discover reviewed VR titles for Art, Math, Science, and more, curated by foundry10.",
                "url": "https://www.foundry10.org/vr-game-review"
              }
            `}
          </script>
        </Helmet>
        <VrLandingPageHeader />
        <VrLandingPageContent />
      </div>
    );
  }
}