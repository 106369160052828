import React, { useState, useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import Grid from "@material-ui/core/Grid";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

//Set Categories
const queryParams = new URLSearchParams(window.location.search);
const cat = queryParams.get("cat");
const themeCat = queryParams.get("themeCat");
const interestCat = queryParams.get("interestCat");
// const [catResult , setCatResult] = useState('');

let allCats = [cat, themeCat].join(",");
if (allCats == ",") {
  allCats = null;
}

// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
let searchParam = params.filter;

// Set search param as empty in value not availible
if (!searchParam) {
  searchParam = "";
}

// resource cat
const resourceCatName = gql`
  query pageQuery($cat_id: [QueryArgument]) {
    categories(group: "resourceType", id: $cat_id) {
      title
    }
  }
`;
//story cat
const CatName2 = gql`
  query pageQuery($cat_id: [QueryArgument]) {
    categories(group: "categories", id: $cat_id) {
      title
    }
  }
`;
// theme cat
const CatName3 = gql`
  query pageQuery($cat_id: [QueryArgument]) {
    categories(group: "themes", id: $cat_id) {
      title
    }
  }
`;
// interestAreas cat
const CatName4 = gql`
  query pageQuery($cat_id: [QueryArgument]) {
    categories(group: "interestAreas", id: $cat_id) {
      title
    }
  }
`;

const ResourceData = (gridData) => {
  const [filterResult, setFilterResult] = useState("");
  const { data, loading, error } = useQuery(resourceCatName, {
    variables: {
      cat_id: cat,
    },
  });
  // setCatResult(data)
  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};
const StoryData = (gridData) => {
  const [filterResult, setFilterResult] = useState("");
  const { data, loading, error } = useQuery(CatName2, {
    variables: {
      cat_id: cat,
    },
  });

  // setCatResult(data)
  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};
const ThemeData = (gridData) => {
  const [filterResult, setFilterResult] = useState("");
  const { data, loading, error } = useQuery(CatName3, {
    variables: {
      cat_id: cat,
    },
  });

  // setCatResult(data)
  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};
const PressThemeData = (gridData) => {
  const { data, loading, error } = useQuery(CatName3, {
    variables: {
      cat_id: themeCat,
    },
  });

  // setCatResult(data)
  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};
const PressInterestData = (gridData) => {
  const { data, loading, error } = useQuery(CatName4, {
    variables: {
      cat_id: interestCat,
    },
  });

  // setCatResult(data)
  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};
const InterestData = (gridData) => {
  const [filterResult, setFilterResult] = useState("");
  const { data, loading, error } = useQuery(CatName4, {
    variables: {
      cat_id: cat,
    },
  });

  // setCatResult(data)
  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};
const FilteredOpportunity = () => {
  const query = gql`
    query pageQuery($cat_id: [QueryArgument]) {
      categories(group: "opportunityType", id: $cat_id) {
        title
      }
    }
  `;
  const { data, loading, error } = useQuery(query, {
    variables: {
      cat_id: cat,
    },
  });

  // setCatResult(data)
  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};
// Get Page content
const entriesQuery = gql`
  query pageQuery(
    $channel: [String]
    $limit: Int!
    $offset: Int!
    $filter: String!
    $themeCat: [QueryArgument]
  ) {
    entries(
      section: $channel
      limit: $limit
      offset: $offset
      search: $filter
      relatedTo: $themeCat
    ) {
      sectionHandle
      title
      slug
      url
      postDate @formatDateTime(format: "F j, Y")
      author {
        fullName
      }
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
        themes {
          title
        }
        categories {
          title
        }
      }
      ... on resources_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        resourceType {
          title
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
      }
      ... on programs_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
        audiences {
          title
        }
      }
      ... on projects_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
        audiences {
          title
        }
      }
      ... on opportunities_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
      }
      ... on press_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        audiences {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
        pressType {
          title
        }
      }
    }
    entryCount(section: $channel, search: $filter, relatedTo: $themeCat)
  }
`;
const themeCatQuery = gql`
  query themeCatQuery {
    categories(group: "themes") {
      categoryId: id
      title
      url
    }
  }
`;
const ThemesData = () => {
  const { data, loading, error } = useQuery(themeCatQuery);
  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return data.categories.map((block, index) => {
    return (
      <li>
        <a href={`?themeCat=${block.categoryId}`}>{block.title}</a>
      </li>
    );
  });
};

const ArchiveCards = (channel) => {
  const searchFilter = searchParam;
  const { data, loading, error, fetchMore } = useQuery(entriesQuery, {
    variables: {
      channel: channel,
      offset: 0,
      limit: 10,
      filter: searchFilter,
      themeCat: allCats,
    },
  });
  const showLoadMore = useMemo(() => {
    if (data) {
      return data.entryCount > data.entries.length;
    }

    return false;
  }, [data]);

  const onLoadMore = () => {
    fetchMore({
      variables: {
        offset: data.entries.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          entries: [...prev.entries, ...fetchMoreResult.entries],
        });
      },
    });
  };

  if (loading) return null;
  if (error) return `Error! ${error}`;

  const currentUrl = window.location.href;

  return (
    <div>
      {searchFilter == "" ? (
        <div></div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "25px",
          }}
        >
          <p>You searched for : {searchFilter} </p>
          <button
            className="button "
            style={{
              background: "transparent",
              color: "#007A8A",
              border: "1px solid",
              padding: "12px",
              lineHeight: "normal",
              fontSize: "13px",
              height: "40px",
            }}
            onClick={() =>
              window.location.replace(window.location.href.split("?")[0])
            }
          >
            Clear Filter
          </button>
        </div>
      )}
      {data.entries.map((block) => {
        //Get Author - first External, then Internal, final Craft Entry author
        let author = "";
        let internalAuthors = "";
        let externalAuthors = "";
        let category = null;
        if (
          block.researchExternalAuthors &&
          block.researchExternalAuthors.length > 0
        ) {
          author =
            block.researchExternalAuthors[0].researchExternalAuthorsAuthor;
        }
        if (block.interestAreaTeam && block.interestAreaTeam.length > 0) {
          internalAuthors = block.interestAreaTeam[0].interestAreaTeamMember
            .map((item) => item.title)
            .join(", ");
        } else if (
          block.__typename == "resources_default_Entry" &&
          typeof block.interestAreaTeam != "undefined"
        ) {
          block.interestAreaTeam.map((headerss) => {
            headerss.interestAreaTeamMember.map((headers) => {
              internalAuthors = block.interestAreaTeam[0].interestAreaTeamMember
                .map((item) => item.title)
                .join(", ");
            });
          });
        }

        // if (block.__typename == "resources_default_Entry") {
        //   author = block.author?.fullName;
        // }

        if (externalAuthors != "" && internalAuthors != "") {
          author = internalAuthors + ", " + externalAuthors;
        } else if (externalAuthors == "" && internalAuthors != "") {
          author = internalAuthors;
        } else if (externalAuthors != "" && internalAuthors == "") {
          author = externalAuthors;
        }

        // Set Category(Story Ttle)
        if (block.categories && block.categories.length) {
          category = block.categories[0].title;
        }

        return (
          <div className="archiveCard large">
            <div className="imageContainer">
              <img
                src={
                  block.featuredImage[0]
                    ? block.featuredImage[0].url
                    : "/news-archive-spacer.png"
                }
                alt={block.title}
              />
            </div>
            <div className="archiveListContent">
              {category && (
                <div className="cardCat">
                  <h5>{category}</h5>
                </div>
              )}
              {block.resourceType && block.resourceType.length && (
                <div className="cardCat">
                  <h5>{block.resourceType[0].title}</h5>
                </div>
              )}
              <h3>
                <a href={currentUrl.split("?filter=")[0].split("?cat=")[0] + '/' + block.slug}>{block.title}</a>
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: block.blurb,
                }}
              ></div>
              <p className="text-body_bold textGray">
                {author && <span>{author} | </span>} {block.postDate}
              </p>
            </div>
          </div>
        );
      })}
      {showLoadMore && (
        <button className="button loadMore" onClick={onLoadMore}>
          Load More
        </button>
      )}
    </div>
  );
};

const ArchiveCardsPress = (channel) => {
  const searchFilter = searchParam;
  allCats = [interestCat, themeCat].join(",");
  if (allCats == ",") {
    allCats = null;
  } else {
    allCats = allCats.replace(",", "");
  }
  const year = params.year;
  const start = ">= " + year + "-01-01";
  const end = "< " + year + "-12-31";
  const entriesQueryYearFilter = gql`
    query pageQuery(
      $channel: [String]
      $limit: Int!
      $offset: Int!
      $filter: String!
      $themeCat: [QueryArgument]
      $start: String!
      $end: String!
    ) {
      entries(
        section: $channel
        limit: $limit
        offset: $offset
        search: $filter
        relatedTo: $themeCat
        postDate: ["and", $start, $end]
      ) {
        sectionHandle
        title
        slug
        url
        postDate @formatDateTime(format: "F j, Y")
        author {
          fullName
        }
        ... on stories_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          themes {
            title
          }
          categories {
            title
          }
        }
        ... on resources_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          themes {
            title
          }
          resourceType {
            title
          }
        }
        ... on programs_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          themes {
            title
          }
          interestAreas {
            title
          }
          guidingQuestions {
            title
          }
          audiences {
            title
          }
        }
        ... on projects_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          themes {
            title
          }
          interestAreas {
            title
          }
          guidingQuestions {
            title
          }
          audiences {
            title
          }
        }
        ... on opportunities_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          themes {
            title
          }
          interestAreas {
            title
          }
          guidingQuestions {
            title
          }
        }
        ... on press_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          themes {
            title
          }
          audiences {
            title
          }
          interestAreas {
            title
          }
          guidingQuestions {
            title
          }
          pressType {
            title
          }
        }
      }
      entryCount(
        section: $channel
        search: $filter
        relatedTo: $themeCat
        postDate: ["and", $start, $end]
      )
    }
  `;
  const { data, loading, error, fetchMore } = useQuery(
    !!year ? entriesQueryYearFilter : entriesQuery,
    !!year
      ? {
          variables: {
            channel: channel,
            offset: 0,
            limit: 10,
            filter: searchFilter,
            themeCat: allCats,
            start: start,
            end: end,
          },
        }
      : {
          variables: {
            channel: channel,
            offset: 0,
            limit: 10,
            filter: searchFilter,
            themeCat: allCats,
          },
        }
  );
  const showLoadMore = useMemo(() => {
    if (data) {
      return data.entryCount > data.entries.length;
    }

    return false;
  }, [data]);

  const onLoadMore = () => {
    fetchMore({
      variables: {
        offset: data.entries.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          entries: [...prev.entries, ...fetchMoreResult.entries],
        });
      },
    });
  };

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <Grid item md={8} sm={12} className="newsletterListContent">
      {data.entries.map((block) => {
        // Don't need to display the author
        let category = null;
        // Set Category(Story Ttle)
        if (block.categories && block.categories.length) {
          category = block.categories[0].title;
        }

        return (
          <div className="archiveCard large">
            {/* <div className="imageContainer">
                      <img src={block.featuredImage[0] ? block.featuredImage[0].url : '/news-archive-spacer.png' } alt={block.title} />
                    </div> */}
            <div className="archiveListContent">
              {category && (
                <div className="cardCat">
                  <h5>{category}</h5>
                </div>
              )}
              {block.resourceType && (
                <div className="cardCat">
                  <h5>{block.resourceType[0].title}</h5>
                </div>
              )}
              <h4 className="text-medium_bold">
                <a href={block.url}>{block.title}</a>
              </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: block.blurb,
                }}
              ></div>
              <p className="text-body_bold textGray">{block.postDate}</p>
            </div>
          </div>
        );
      })}
      {showLoadMore && (
        <button className="button loadMore" onClick={onLoadMore}>
          Load More
        </button>
      )}
    </Grid>
  );
  return <a>test</a>;
};
function PressTimes() {
  var [year, setYear] = useState();
  const [page, setPage] = useState(0);
  if (allCats != null) {
    allCats = allCats.replace(",", "");
  }
  const urlYear = params.year;
  const pressAllYearQuery = gql`
    query nenewsletterQuerywsletterQuery {
      entries(section: "press", orderBy: "postDate desc") {
        title
        postDate @formatDateTime(format: "Y")
      }
    }
  `;

  const entriesQuery = gql`
    query pageQuery(
      $channel: [String]
      $limit: Int!
      $offset: Int!
      $filter: String!
      $themeCat: [QueryArgument]
    ) {
      entries(
        section: $channel
        limit: $limit
        offset: $offset
        search: $filter
        relatedTo: $themeCat
      ) {
        sectionHandle
        title
        slug
        url
        postDate @formatDateTime(format: "F j, Y")
        author {
          fullName
        }
        ... on stories_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          themes {
            title
          }
          categories {
            title
          }
        }
        ... on resources_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          themes {
            title
          }
          resourceType {
            title
          }
        }
        ... on programs_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          themes {
            title
          }
          interestAreas {
            title
          }
          guidingQuestions {
            title
          }
          audiences {
            title
          }
        }
        ... on projects_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          themes {
            title
          }
          interestAreas {
            title
          }
          guidingQuestions {
            title
          }
          audiences {
            title
          }
        }
        ... on opportunities_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          themes {
            title
          }
          interestAreas {
            title
          }
          guidingQuestions {
            title
          }
        }
        ... on press_default_Entry {
          blurb
          featuredImage {
            title
            url
          }
          themes {
            title
          }
          audiences {
            title
          }
          pressType {
            title
          }
          interestAreas {
            title
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
                url
              }
            }
          }
          guidingQuestions {
            title
          }
          contentOptions {
            ... on contentOptions_standardContent_BlockType {
              contentTitle
              contentContent
              linkUrl {
                url
                customText
                target
              }
              contentAlignment
            }
            ... on contentOptions_icp_BlockType {
              icpTitle
              icpContent
              icpImage {
                url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
            }
            ... on contentOptions_fwi_BlockType {
              fwiTitle
              fwiImage {
                url
              }
              fwiCaption
              fwiSize
            }
            ... on contentOptions_emphasized_BlockType {
              emphasizedTitle
              emphasizedSubheadline
              emphasizedOverline
              emphasizedImage {
                url
              }
              emphasizedImageCaption
              emphasizedContent
              emphasizedLink
              emphasizedBackgroundColor
            }
            ... on contentOptions_quote_BlockType {
              pullQuote
              quoteSocial
              quoteImage {
                url
              }
              quoteAttribution
            }
            ... on contentOptions_quoteSlideshow_BlockType {
              quoteSliderTitle
              quotes {
                __typename
              }
            }
            ... on contentOptions_slider_BlockType {
              sliderTitle
              slides {
                __typename
                ... on slides_BlockType {
                  slideImage {
                    url
                  }
                  slideHeadline
                  slideContent
                  slideLink {
                    customText
                    url
                    target
                  }
                }
              }
            }
            ... on contentOptions_accordion_BlockType {
              accordionTitle
              accordion {
                __typename
                ... on accordion_BlockType {
                  accordionItemTitle
                  accordionItemBlurb
                  accordionItemImage {
                    url
                  }
                  accordionItemIcon {
                    url
                  }
                  accordionItemContent
                }
              }
            }
            ... on contentOptions_list_BlockType {
              listTitle
              list {
                __typename
                ... on list_BlockType {
                  listItemHeadline
                  listItemContent
                  listItemImage {
                    url
                  }
                  listItemLink {
                    url
                    customText
                    target
                  }
                }
              }
            }
            ... on contentOptions_linkList_BlockType {
              linkListTitle
              linkListList {
                __typename
                ... on linkListList_BlockType {
                  linkListTitle
                  linkUrl
                  linkListBg {
                    url
                  }
                  linkListHoverTxt
                  linkListTitle
                }
              }
              linkListDisplay
              linkListSize
              columnCount
            }
            ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
            ... on contentOptions_featuredResource_BlockType {
              featuredResource {
                title
                slug
                url
                ... on resources_default_Entry {
                  blurb
                  thumbnailImage {
                    url
                  }
                  researchAsset {
                    url
                    title
                  }

                  interestAreaTeam {
                    ... on interestAreaTeam_BlockType {
                      interestAreaTeamMember {
                        title
                        url
                      }
                    }
                  }
                  researchExternalAuthors {
                    ... on researchExternalAuthors_BlockType {
                      researchExternalAuthorsAuthor
                    }
                  }
                  featuredImageCaption
                }
              }
            }
            ... on contentOptions_featuredPerson_BlockType {
              featuredPerson {
                title
                url
                ... on teamMembers_default_Entry {
                  teamMembersPronouns
                  teamMemberRoles {
                    title
                  }
                  teamMemberImage {
                    url
                  }
                  teamMemberPosition
                  teamMemberBio
                }
              }
            }
            ... on contentOptions_featuredStory_BlockType {
              featuredstory {
                title
                url
                slug
                author {
                  fullName
                }
                postDate @formatDateTime(format: "M j, Y")
                ... on stories_default_Entry {
                  blurb
                  featuredImage {
                    title
                    url
                  }
                  categories {
                    title
                  }
                  themes {
                    title
                  }
                  interestAreas {
                    title
                  }
                  researchExternalAuthors {
                    ... on researchExternalAuthors_BlockType {
                      researchExternalAuthorsAuthor
                    }
                  }
                  interestAreaTeam {
                    ... on interestAreaTeam_BlockType {
                      interestAreaTeamMember {
                        title
                      }
                    }
                  }
                }
              }
            }
            ... on contentOptions_thirdPartyCode_BlockType {
              thirdPartyCodeTitle
              thirdPartyCodeCode
            }
          }
        }
      }
      entryCount(section: $channel, search: $filter, relatedTo: $themeCat)
    }
  `;

  const PAGE_SIZE = 10;
  const k = useQuery(entriesQuery, {
    variables: {
      limit: page * PAGE_SIZE + 10,
    },
  });
  const { data, loading, error, fetchMore } = useQuery(pressAllYearQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  const setYearUrl = (year) => {
    if (urlYear == year) {
      window.location.href = "/news/press";
    } else {
      setYear(year);
      var queryParams = new URLSearchParams(window.location.search);
      const p = data.entries.map((block) => {
        return block.postDate;
      });
      // Set new or modify exi  sting parameter value.
      queryParams.set("year", year);
      window.location.href = "/news/press?" + queryParams;
    }
  };
  var res = [];
  var l = [];
  return (
    <Grid item md={3} sm={12} className="newsletterSidebar">
      <h3 className="text-half-step_strong">Year</h3>
      <ul style={{ list_style_type: "none" }} className="yearList">
        {data.entries.map((block) => {
          {
            l.push(block.postDate);
          }
        })}
        <p style={{ display: "none" }}> {(res = [...new Set(l)])}</p>

        {res.map((filterDate) => {
          return (
            <li>
              <input
                onClick={(e) => setYearUrl(filterDate)}
                checked={
                  urlYear == filterDate
                    ? true
                    : year == filterDate
                    ? true
                    : false
                }
                type="checkbox"
                value={filterDate}
                className="form-checkbox checkbox"
              />
              <span>{filterDate}</span>
            </li>
          );
        })}
      </ul>
    </Grid>
  );
}

const ArchiveList = ({ channel }) => {
  const searchFilter = searchParam;
  return (
    <div>
      {channel == "press" ? (
        <section id="archiveList">
          <div className="container">
            {/* Show Filtered Options */}
            {!!themeCat || !!interestCat || !!searchFilter ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                {!!searchFilter && <p>You searched for : {searchFilter} </p>}
                {!!themeCat && <PressThemeData />}
                {!!interestCat && <PressInterestData />}
                <button
                  className="button "
                  style={{
                    background: "transparent",
                    color: "#007A8A",
                    border: "1px solid",
                    padding: "12px",
                    lineHeight: "normal",
                    fontSize: "13px",
                    height: "40px",
                  }}
                  onClick={() =>
                    window.location.replace(window.location.href.split("?")[0])
                  }
                >
                  Clear Filter
                </button>
              </div>
            ) : (
              <div></div>
            )}
            <Grid container spacing={40}>
              {/* Year */}
              <PressTimes />
              {/* List */}
              <ArchiveCardsPress channel={channel} />
            </Grid>
          </div>
        </section>
      ) : (
        <section id="archiveList">
          <div className="container">
            {cat == null ? (
              <div></div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                <div>
                  <ResourceData />
                  <StoryData />
                  <ThemeData />
                  <InterestData />
                  <FilteredOpportunity />
                </div>
                <button
                  className="button "
                  style={{
                    background: "transparent",
                    color: "#007A8A",
                    border: "1px solid",
                    padding: "12px",
                    lineHeight: "normal",
                    fontSize: "13px",
                    height: "40px",
                  }}
                  onClick={() =>
                    window.location.replace(window.location.href.split("?")[0])
                  }
                >
                  Clear Filter
                </button>
              </div>
            )}
            <ArchiveCards channel={channel} />
          </div>
        </section>
      )}
    </div>
  );
};

export default ArchiveList;
