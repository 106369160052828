import React from "react";
import Accordion from "../../../components/blocks/Accordion";

const CCLLSTopInlineContent = ({ entry }) => {
  return entry.contentOptions.map((block, index) => (
    <React.Fragment key={index}>
      {block.__typename === "contentOptions_accordion_BlockType" && (
        <Accordion block={block} />
      )}
    </React.Fragment>
  ));
};

const CCLLSTopContentBlocks = ({ data }) => {
  return (
    <div className="contentBlocks-container">
      <CCLLSTopInlineContent entry={data} />
    </div>
  );
};

export default CCLLSTopContentBlocks;