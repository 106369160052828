import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import StemGoBabyGoHeader from './stemGoBabyGo-Header';
import StemGoBabyGoContent from './stemGoBabyGo-Content';
import './stemGoBabyGo.css';

export default class StemGoBabyGo extends Component {
  render() {
    return(
      <div id="stemGoBabyGo-container">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <StemGoBabyGoHeader />
        <StemGoBabyGoContent />
      </div>
    );
  }
}