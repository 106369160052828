import React, { Component } from 'react';
import './stemDbrLab-Members.css';

export default class StemDbrLabMembers extends Component {
  render() {
    return (
      <div className="stemDbrLabMembers-container">
        <div className="stemDbrLabMembers-content-container">
          <h2>Current Lab Members</h2>
          <ul className="stemDbrLabMembers-cardsList">
            <li>
              <a href="/about/team/travis-willingham-windleharth" className="conceptualDevelopmentLabMembers-link">
                <div className="stemDbrLabMembers-card">
                  <div className="stemDbrLabMembers-card-image">
                    <img src='https://f10-people.s3-us-west-2.amazonaws.com/Re-edited+Team+Photos+230x300/Most+Recent/Travis+Willingham+Windleharth.jpg' width="100%" alt="man in grey sweater"/>
                  </div>
                  <div className="stemDbrLabMembers-card-content">   
                    <h2>Travis Willingham Windleharth</h2>
                    <p>Principal Investigator</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="about/team/cat-arley" className="conceptualDevelopmentLabMembers-link">
                <div className="stemDbrLabMembers-card">
                  <div className="stemDbrLabMembers-card-image">
                    <img src='https://f10-people.s3.us-west-2.amazonaws.com/Re-edited+Team+Photos+230x300/Cat.jpg' width="100%" alt="woman in striped shirt"/>
                  </div>
                  <div className="stemDbrLabMembers-card-content">   
                    <h2>Cat Arley</h2>
                    <p>Associate Researcher</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}