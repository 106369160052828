import React, { Component } from 'react';
import './PhilanthropyLandingPage-Stories.css';

export default class PhilanthropyLandingPageStories extends Component {
  render() {
    return(
      <div id="research-interestArea-container">
        <div class="research-interestArea-content-container-philanthropy">
          <div class="research-interestArea-title-research">
            <h2>Philanthropy Stories</h2>
          </div>
          <ul id="research-interestArea-cardsList2">
          <a href="/stories/recording-studio-opens-up-to-young-entrepreneurs-interested-in-music" class="research-interestArea-link">
            <li>
              <div class="researchList-card-container">
                <div class="researchList-card-content-container">
                  <div class="researchList-card-image-container">
                    <img className="philanthropyStory-img-darker" src="https://f10-philanthropy.s3.us-west-2.amazonaws.com/Images/Recording+Studio+Opens+Up+to+Young+Entrepreneurs+Interested+In+Music.jpg" alt="Recording Studio Opens Up to Young Entrepreneurs Interested In Music"/>
                  </div>
                  <div class="researchList-card-text-container">
                    <div class="researchList-card-mainText-research">
                      <h2>Recording Studio Opens Up to Young Entrepreneurs Interested In Music</h2>
                      <p>With a shared interest in expanding unique career-learning opportunities for youth, SPIN's Young Entrepreneur Project, the Recreational Psychoacoustics Lab, and foundry10 collaborated to offer a two-day program where youth experienced a music recording session in a world-class studio with professional musicians.</p>
                    </div>
                    <div class="researchList-card-bottomText">
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </a>
          <a href="/stories/wonderland-child-family-services-supporting-youth-with-diverse-needs-and-abilities" class="research-interestArea-link">
            <li>
              <div class="researchList-card-container">
                <div class="researchList-card-content-container">
                  <div class="researchList-card-image-container">
                    <img className="philanthropyStory-img-darker" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Collaborative+Philanthropy/Wonderland/Wonderland+200x200+Thumbnail.jpg" alt="child playing with caretaker"/>
                  </div>
                  <div class="researchList-card-text-container">
                    <div class="researchList-card-mainText-research">
                      <h2>Wonderland Child & Family Services: Supporting Youth with Diverse Needs and Abilities</h2>
                      <p>The first three years of a young person’s life are some of the most critical in terms of brain growth and development (mental, physical, and emotional). Learn how foundry10 worked with Wonderland Child & Family Services to provide comprehensive care to youth with developmental delays, disabilities, and prenatal substance exposure.</p>
                    </div>
                    <div class="researchList-card-bottomText">
                      {/* <h4>{records.researchType}</h4> */}
                      {/* <h4>•</h4>
                      <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </a>
          <a href="/stories/hilltop-school-supporting-pregnant-and-parenting-students" class="research-interestArea-link">
            <li>
              <div class="researchList-card-container">
                <div class="researchList-card-content-container">
                  <div class="researchList-card-image-container">
                    <img className="philanthropyStory-img-darker" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Collaborative+Philanthropy/Hilltop+School/Thumbnail_Hilltop_Philanthropy-Story_200-by-200.jpg" alt="pregnant students talking"/>
                  </div>
                  <div class="researchList-card-text-container">
                    <div class="researchList-card-mainText-research">
                      <h2>Hilltop School: Supporting Pregnant and Parenting Students</h2>
                      <p>Teen pregnancy has become less and less common in the U.S. over the past decades, but youth who are pregnant or who have recently given birth continue to face exceptional educational challenges. Learn how foundry10 has worked with San Francisco's Hilltop School to help provide holistic support to pregnant and parenting students.</p>
                    </div>
                    <div class="researchList-card-bottomText">
                      {/* <h4>{records.researchType}</h4> */}
                      {/* <h4>•</h4>
                      <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </a>
          <a href="/stories/comics-to-classrooms" class="research-interestArea-link">
            <li>
              <div class="researchList-card-container">
                <div class="researchList-card-content-container">
                  <div class="researchList-card-image-container">
                    <img src="https://f10-philanthropy.s3.us-west-2.amazonaws.com/Images/Comics+to+Classrooms/Comics-to-Classrooms_200x200-Thumbnail.jpg" alt="youth reading a comic book together"/>
                  </div>
                  <div class="researchList-card-text-container">
                    <div class="researchList-card-mainText-research">
                      <h2>Comics to Classrooms</h2>
                      <p>Comics, including sequential art, graphic novels, and manga can improve visual literacy, foster community through fandom, empower youth to tell their own stories, and more. The Comics to Classrooms program at foundry10 brought quality comics and graphic novels to youth while supporting local bookstores and artists.</p>
                    </div>
                    <div class="researchList-card-bottomText">
                      {/* <h4>{records.researchType}</h4> */}
                      {/* <h4>•</h4>
                      <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </a>
          <a href="/stories/art-and-education-the-value-of-high-quality-materials" class="research-interestArea-link">
            <li>
              <div class="researchList-card-container">
                <div class="researchList-card-content-container">
                  <div class="researchList-card-image-container">
                    <img src="https://f10-philanthropy.s3.us-west-2.amazonaws.com/Images/Art+Education+Story/Art+and+Education+small.jpg" alt="a young girl smiling while painting artwork on a canvas"/>
                  </div>
                  <div class="researchList-card-text-container">
                    <div class="researchList-card-mainText-research">
                      <h2>Art and Education: The Value of High Quality Materials</h2>
                      <p>The Artistic Design team at foundry10 has been dedicated to making arts more accessible to youth for many years. foundry10 partnered with the volunteer art teacher at Mountlake Terrace Elementary School in late 2021 to provide art supplies to twenty-three classrooms from kindergarten to sixth grade.</p>
                    </div>
                    <div class="researchList-card-bottomText">
                      {/* <h4>{records.researchType}</h4> */}
                      {/* <h4>•</h4>
                      <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </a>
          <a href="/stories/youth-and-parent-voices-the-importance-of-drivers-ed-access" class="research-interestArea-link">
            <li>
              <div class="researchList-card-container">
                <div class="researchList-card-content-container">
                  <div class="researchList-card-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Collaborative+Philanthropy/The+Importance+of+Drivers+Ed+Access/The+Importance+of+Drivers+Ed+Access+Header+small.jpg" alt="driving teacher instructing driving student in vehicle"/>
                  </div>
                  <div class="researchList-card-text-container">
                    <div class="researchList-card-mainText-research">
                      <h2>Youth and Parent Voices: The Importance of Driver's Ed Access</h2>
                      <p>foundry10 partnered with youth advocates at Mt. Vernon High School in early 2022 to provide scholarships to 32 students who otherwise could not attend Driver's Ed. Many of these students provided direct feedback that this support was life-changing for them and their families.</p>
                    </div>
                    <div class="researchList-card-bottomText">
                      {/* <h4>{records.researchType}</h4> */}
                      {/* <h4>•</h4>
                      <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </a>
          <a href="/stories/the-benefits-and-pitfalls-of-organizational-flexibility-in-crisis-response" class="research-interestArea-link">
            <li>
              <div class="researchList-card-container">
                <div class="researchList-card-content-container">
                  <div class="researchList-card-image-container">
                    <img src="https://f10-philanthropy.s3.us-west-2.amazonaws.com/Images/the+benefits+and+pitfalls+of+organizational+flexibility+in+crisis+response/The+Benefits+and+Pitfalls+of+Organizational+Flexibility+in+Crisis+Response+small.jpg" alt="a group of diverse hands joining together"/>
                  </div>
                  <div class="researchList-card-text-container">
                    <div class="researchList-card-mainText-research">
                      <h2>The Benefits and Pitfalls of Organizational Flexibility in Crisis Response</h2>
                      <p>Our 3 month timeline, small team, and open-ended outreach to more than 42 outside groups, made foundry10’s flexible pandemic relief response equally exciting, stressful, and impactful.</p>
                    </div>
                    <div class="researchList-card-bottomText">
                      {/* <h4>{records.researchType}</h4> */}
                      {/* <h4>•</h4>
                      <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </a>
          <a href="/stories/rural-schools-project-story" class="research-interestArea-link">
              <li>
                <div class="researchList-card-container">
                  <div class="researchList-card-content-container">
                    <div class="researchList-card-image-container">
                      <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Collaborative+Philanthropy/Rural+Schools/rural-schools-header.jpg" alt="school bus on rural route"/>
                    </div>
                    <div class="researchList-card-text-container">
                      <div class="researchList-card-mainText-research">
                        <h2>Rural Schools Project</h2>
                        <p>foundry10 launched our Rural Schools Project in Fall 2021 to help address the pressing needs of rural students and teachers through direct partnerships with schools. This project aims to provide swift, sustainable, and community responsive support to help enhance student education experiences, reduce hurdles for teachers, and strengthen schools as hubs of community well-being.</p>
                      </div>
                      <div class="researchList-card-bottomText">
                        {/* <h4>{records.researchType}</h4> */}
                        {/* <h4>•</h4>
                        <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </a>
            <a href="/stories/ptsa-story-building-connections-to-achieve-community-goals" class="research-interestArea-link">
              <li>
                <div class="researchList-card-container">
                  <div class="researchList-card-content-container">
                    <div class="researchList-card-image-container">
                      <img src="https://f10-philanthropy.s3-us-west-2.amazonaws.com/Images/PTSA+STORY+small.jpg" alt="ptsa story"/>
                    </div>
                    <div class="researchList-card-text-container">
                      <div class="researchList-card-mainText-research">
                        <h2>PTSA Story: Building Connections to Achieve Community Goals</h2>
                        <p>By helping to forge meaningful connections with and within communities, and assisting in the pursuit of a community-defined vision, we can improve internal structures for longer-term gains. This type of support can bring about some of the most effective lasting changes in an educational environment.</p>
                      </div>
                      <div class="researchList-card-bottomText">
                        {/* <h4>{records.researchType}</h4> */}
                        {/* <h4>•</h4>
                        <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </a>
            <a href="/stories/community-responsive-philanthropy" class="research-interestArea-link">
              <li>
                <div class="researchList-card-container">
                  <div class="researchList-card-content-container">
                    <div class="researchList-card-image-container">
                      <img src="https://f10-philanthropy.s3-us-west-2.amazonaws.com/Images/community+responsive+small.jpg" alt="community responsive philanthropy"/>
                    </div>
                    <div class="researchList-card-text-container">
                      <div class="researchList-card-mainText-research">
                        <h2>Community Responsive Philanthropy</h2>
                        <p>By structuring a philanthropic partnership in a way that encourages and facilitates open and honest dialogue, we can ensure that even short-term or one-time philanthropic efforts are responsive to community needs.</p>
                      </div>
                      <div class="researchList-card-bottomText">
                        {/* <h4>{records.researchType}</h4> */}
                        {/* <h4>•</h4>
                        <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </a>
            <a href="/stories/supporting-a-take-home-book-program" class="research-interestArea-link">
              <li>
                <div class="researchList-card-container">
                  <div class="researchList-card-content-container">
                    <div class="researchList-card-image-container">
                      <img src="https://f10-philanthropy.s3-us-west-2.amazonaws.com/Images/Take+Home+Book+small.jpg" alt="take home book program"/>
                    </div>
                    <div class="researchList-card-text-container">
                      <div class="researchList-card-mainText-research">
                        <h2>Supporting a Take-Home Book Program</h2>
                        <p>Take-home book programs provide students with access to enjoyable, high-interest books that they can take home to their families so students can both develop literacy skills and grow their love of learning. You can create a valuable experience for young people by funding something that schools, often, cannot!</p>
                      </div>
                      <div class="researchList-card-bottomText">
                        {/* <h4>{records.researchType}</h4> */}
                        {/* <h4>•</h4>
                        <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </a>
            <a href="/stories/our-work-as-a-multifaceted-organization" class="research-interestArea-link">
              <li>
                <div class="researchList-card-container">
                  <div class="researchList-card-content-container">
                    <div class="researchList-card-image-container">
                      <img src="https://f10-philanthropy.s3-us-west-2.amazonaws.com/Images/Mult+faceted+org+small.jpg" alt="our work as a multifaceted organization"/>
                    </div>
                    <div class="researchList-card-text-container">
                      <div class="researchList-card-mainText-research">
                        <h2>Our Work as a Multifaceted Organization</h2>
                        <p>Our work requires a team that brings both the unique skills for one, or a few, of each of the distinct aspects of our work, as well as a willingness to collaborate and learn beyond the skills they initially bring. Here’s how our multifaceted organizational structure has allowed us to be flexible and responsive in our work with communities.</p>
                      </div>
                      <div class="researchList-card-bottomText">
                        {/* <h4>{records.researchType}</h4>
                        <h4>•</h4>
                        <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </a>
          </ul>
        </div>
      </div>
    );
  }
}