import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './SubjectArea2.css';
import axios from 'axios';
import BigCard from './Big-Card';
import GetArtsFromDb from './GetArtsFromDb2';
import GetTechFromDb from './GetTechFromDb2';
import GetVocationalFromDb from './GetVocationalFromDb2';
import GetGeneralEdFromDb from './GetGeneralEdFromDb2';
import GetNonAcademicFromDb from './GetNonAcademicFromDb2';
// import Pillars from './Pillars';
// import Impact from './Impact';
// import InterestAreaResearch from './InterestArea-Research';
import NewFooter from '../../pages-other/NewFooter';

import Error404Page from '../../pages-other/NewSite404/newSite404';

export default class SubjectArea2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subjectArea: {},
      artsDomains: [],
      techDomains: [],
      vocationalDomains: [],
      generalEdDomains: [],
      nonAcademicDomains: [],
      questions: [],
      allResearch: []
    };
  }

  // componentDidMount() {
  //   const name = this.props.props.match.params.name;
  //   axios.get('/programs/getIndividSubjectArea/'+name, {
  //     data: name
  //   }).then(data => {
  //       this.setState({
  //         subjectArea: data.data,
  //         questions: data.data.questions
  //       })
  //     }).catch(err => {
  //       console.log('err in /getPrograms: ', err);
  //     })
  // }

  componentDidMount() {

    // Redirect due to program name change in March 2022
    if(this.props.props.match.params.name === 'virtual-reality'){
      this.props.props.match.params.name = 'edtech';
      window.history.replaceState({}, 'edtech', '/programs/edtech');
    };

    const name = this.props.props.match.params.name

    axios.all([
      axios.get('/programs2/getIndividSubjectArea/'+name),
      axios.get('/programs2/getArtsDomains/'+name),
      axios.get('/programs2/getTechDomains/'+name),
      axios.get('/programs2/getVocationalDomains/'+name),
      axios.get('/programs2/getGeneralEdDomains/'+name),
      axios.get('/programs2/getNonAcademicDomains/'+name)
    ])
    .then(axios.spread((subject1, subject2, subject3, subject4, subject5, subject6) => {
      this.setState({
        subjectArea: subject1.data,
        artsDomains: subject2.data,
        techDomains: subject3.data,
        vocationalDomains: subject4.data,
        generalEdDomains: subject5.data,
        nonAcademicDomains: subject6.data,
        questions: subject1.data.questions,
        allResearch: subject1.data.allResearch
      })
    })).catch(err => {
      console.log('err in /getPrograms: ', err);
      this.setState({subjectArea: null})
    });
  }

  render() {
    if (this.state.subjectArea) {
    // console.log('What is this.state.subjectArea?', this.state.subjectArea);
    // console.log(this.state.subjectArea);

      return (
        <div id="interestArea-main-container">
          <Helmet>
            <title>{this.state.subjectArea.seoTitle}</title>
            <meta
              name="title"
              content={this.state.subjectArea.seoTitle}
            />
            <meta
              name="description"
              content={this.state.subjectArea.seoDescription}
            />
            <meta property="og:title" content={this.state.subjectArea.seoTitle} />
            <meta
              property="og:description"
              content={this.state.subjectArea.seoDescription}
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={this.state.subjectArea.seoUrl} />
            <link rel="canonical" href={this.state.subjectArea.seoUrl} />

            {/* Prevents search engine crawlers from indexing page */}
            <meta name="robots" content="noindex" />
            
            <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org",
                  "@type": "WebPage",
                  "name": "${this.state.subjectArea.seoTitle}",
                  "description": "${this.state.subjectArea.seoDescription}",
                  "url": "${this.state.subjectArea.seoUrl}"
                }
              `}
            </script>
          </Helmet>
          {/* HEADER */}
          {/* <div class="interestArea-header-container" style={{backgroundImage: "url(" + `${this.state.subjectArea.headerImageUrl}` + ")"}}> */}
          <div class="interestArea-header-container" style={{backgroundImage: `url(${this.state.subjectArea.headerImageUrl})`}}>
            <div class="interestArea-header-content-container">
              <h1>{this.state.subjectArea.name}</h1>
            </div>
          </div>
          {/* BODY */}
          <div id="interestArea-main-content-container">
            {/* DESCRIPTION */}
            <div id="interestArea-description-container">
              <div class="interestArea-description-content-container">
                <p>{this.state.subjectArea.description}</p>
              </div>
            </div>
            {/* PROGRAMS & PROJECTS */}
            <div id="interestArea-main-programs">
              <div class="interestArea-title-container">
                <h1>Programs & Projects</h1>
                <p>Check out the work we have done in collaboration with dedicated educators.</p>
              </div>
              <div id="interestArea-Cards-Data2-Container">
                <BigCard all={this.state.subjectArea.bigCard} />
                <GetArtsFromDb data={this.state.subjectArea} all={this.state.artsDomains} />
                <GetGeneralEdFromDb data={this.state.subjectArea} all={this.state.generalEdDomains} />
                <GetTechFromDb data={this.state.subjectArea} all={this.state.techDomains} />
                <GetNonAcademicFromDb data={this.state.subjectArea} all={this.state.nonAcademicDomains} />
                <GetVocationalFromDb data={this.state.subjectArea} all={this.state.vocationalDomains} />
              </div>
            </div>
            {/* PILLARS */}
            {/* <div id="interestArea-pillars-container">
              <div class="interestArea-pillars-content-container">
                <div class="interestArea-pillars-title-description-container">
                  <div class="interestArea-pillars-title-content-container">
                    <h1>Guiding Principles</h1>
                    <p>Guiding principles are our broad philosophy that encompass the beliefs and values within this subject area.</p>
                    <div class="pillars-title-line"></div>
                  </div>
                </div>
                <div class="interestArea-pillars-box-container">
                  <Pillars data={this.state.subjectArea} all={this.state.subjectArea.pillars} />
                </div>
              </div>
            </div> */}
            {/* IMPACT */}
            {/* <div id="interestArea-impact-container">
              <div class="interestArea-impact-content-container">
                <div class="interestArea-impact-title-description-container">
                  <div class="interestArea-impact-title-content-container">
                    <h1>Impact</h1>
                    <div class="impact-title-line"></div>
                  </div>
                </div>
                <div class="interestArea-impact-box-container">
                  <Impact data={this.state.subjectArea} all={this.state.subjectArea.impact} />
                </div>
              </div>
            </div> */}
            {/* QUOTE */}
            {/* <div id="interestArea-quote-container" style={{position: "static", backgroundSize: "cover", backgroundPosition: "center center", backgroundImage: "url(" + `${this.state.subjectArea.quoteImageUrl}` + ")"}}> */}
            <div id="interestArea-quote-container" style={{position: "static", backgroundSize: "cover", backgroundPosition: "center center", backgroundImage: `url(${this.state.subjectArea.quoteImageUrl})`}}>
              <div class="interestArea-quote-content-container">
                <div class="interestArea-quote-text">
                  <h2>"{this.state.subjectArea.quote}"</h2>
                  <h4>{this.state.subjectArea.quoteName}</h4>
                </div>
              </div>
            </div>
            {/* RESEARCH */}
            {/* <div id="interestArea-research-container">
              <div class="interestArea-research-content-container">
              <InterestAreaResearch all={this.state.allResearch} />
              </div>
            </div> */}
            <NewFooter />
          </div>
        </div>
      )
    } else {
      return (
          <Error404Page />
        )
    }
  }
}

