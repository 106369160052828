import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

// ::Notes::
// - Want to clean this up so there's not block.block.block
// - Clean up params passed
// ::END Notes::
const styles = {
    card: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
  };
  
  function StoryCard(block) {
    
    let category = null;
    let imageUrl = '/news-archive-spacer.png';

    // Set Image URL if availible
    if (block.block.block.featuredImage.length > 0) {
        imageUrl = block.block.block.featuredImage[0].url;
    }
    
    // Set Category Label 
    if (block.block.block.resourceType && block.block.block.resourceType.length > 0) {
      category = block.block.block.resourceType[0].title;
    } else if (block.block.block.categories && block.block.block.categories.length > 0) {
      category = block.block.block.categories[0].title;
    } else if (block.block.block.pressType && block.block.block.pressType.length > 0) {
      category = block.block.block.pressType[0].title;
    }
   
    //Get Author - first External, then Internal, final Craft Entry author
    let author = '';
    let internalAuthors = '';
    let externalAuthors = '';

    if(block.block.block.researchExternalAuthors && block.block.block.researchExternalAuthors.length) {
        author = block.block.block.researchExternalAuthors[0].researchExternalAuthorsAuthor
    }  if (block.block.block.interestAreaTeam && block.block.block.interestAreaTeam.length > 0) {
    internalAuthors = block.block.block.interestAreaTeam[0].interestAreaTeamMember.map(item => item.title).join(', ');
  }
    if(externalAuthors != '' && internalAuthors != '') {
        author = internalAuthors+ ', '+ externalAuthors
      } else if (externalAuthors == '' && internalAuthors != '') {
        author = internalAuthors
      } else if (externalAuthors != '' && internalAuthors == '') {
        author = externalAuthors
      }
    
    if (block.orientation == 'wide') {
        return (
            <div className="archiveCard large">
                {imageUrl && (
                    <div className="imageContainer">
                        <img src={imageUrl} alt={block.block.block.title} />
                    </div>
                )} 

                <div className="archiveListContent">
                  {category && (
                    <div className='cardCat'>
                      <h5>
                        {category}
                      </h5>
                    </div>
                  )}
                  <h3>
                      <a href={block.block.block.url}>
                          {block.block.block.title}
                      </a>
                  </h3>
                <div
                    dangerouslySetInnerHTML={{
                    __html: block.block.block.blurb,
                    }}
                ></div>
               <p className="storyDate text-small_strong">{author ? ( author  + " | " ) : ""}{block.block.block.postDate}</p>
            </div>
            
          </div>
        );
    } else {
        return (

        <Card className='storyCard'>
            <CardMedia
                className='image'
                image={imageUrl}
                title={block.block.block.title}
            />
            <CardContent>
                {category && (
                    <h5 className="cardCat">{category}</h5>
                )}
                {block.block.block.title && (
                    <a href={block.block.block.url}><h3 className="text-medium_bold">{block.block.block.title}</h3></a>
                )}
                <div
                    dangerouslySetInnerHTML={{
                    __html: block.block.block.blurb,
                    }}
                ></div>
                <p className="storyDate text-small_strong text-uppercase">
                    {author ? ( author  + " | " ) : ""}{block.block.block.postDate}
                </p>
            
            </CardContent>
            
        </Card>
        );
    }
  }
  
  export default StoryCard;