import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
  display: flex;
  color: #111111;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px 15px 25px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  text-align: left;
  font-family: Helvetica Neue Medium;
  font-size: 16px;
  white-space: wrap;

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 12px;
`;

// Note: change display setting below when we add in new icons.  Be sure text alignment looks consistent when text wraps.

const SidebarIcon = styled.span`
  display: none;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;

const DropdownLink = styled(Link)`
  background: #f9f9f9;
  height: 60px;
  padding: 15px 15px 15px 25px;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: left;
  color: #111111;
  font-family: Helvetica Neue Medium;
  font-size: 16px;
  white-space: wrap;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const handleLinkClick = (url) => {
  // Perform a full page reload by setting window.location.href to the specified URL
  window.location.href = url;
};


const SubMenu = ({ item, closeSidebar }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);
  



  return (
    <div>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav} >
        <div>
          {item.title}
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index} 
              onClick={() => {
              closeSidebar();
              handleLinkClick(item.path);}}>
              <SidebarIcon>{item.icon}</SidebarIcon>
              <SidebarLabel>{item.title}</SidebarLabel>         
            </DropdownLink>
          );
        })}
    </div>

    // TEST START (KEEP FOR NOW)
    // <div>
    //   <SidebarLinkSubMenu onClick={showSubnav}>
    //     <div>
    //       {'Research'}
    //     </div>
    //     <div>
    //       {SidebarData[0].subNav && subnav
    //         ? SidebarData[0].iconOpened
    //         : SidebarData[0].subNav
    //         ? SidebarData[0].iconClosed
    //         : null}
    //     </div>
    //   </SidebarLinkSubMenu>
    //   {subnav &&
    //     SidebarData[0].subNav.map((item, index) => {
    //       return (
    //         <DropdownLink to={item.path} key={index} onClick={closeSidebar}>
    //           <SidebarIcon>{item.icon}</SidebarIcon>
    //           <SidebarLabel>{item.title}</SidebarLabel>         
    //         </DropdownLink>
    //       );
    //     })}
    // </div>
    // TEST END
  );
};

export default SubMenu;
