import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import VrResourcesHeader from './vrResources-Header';
import VrResourcesContent from './vrResources-Content';
import './vrResources.css';

export default class VrResources extends Component {
  render() {
    return(
      <div id="vrResources-container">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <VrResourcesHeader />
        <VrResourcesContent />
      </div>
    );
  }
}