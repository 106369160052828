import React, { useState, useEffect } from 'react';
import './banner.css';

const Banner = () => {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const isBannerClosed = localStorage.getItem('bannerClosed');
    if (isBannerClosed) {
      setShowBanner(false);
    }
  }, []);

  const handleBannerDismiss = () => {
    setShowBanner(false);
    localStorage.setItem('bannerClosed', 'true');
  };

  if (!showBanner) {
    return null; // Don't render the banner if showBanner is false
  }

  return (
    
    <div className="f10-banner-container">
      <div className="f10-banner-content-container">
        <div className="f10-banner-content-text-container">
          {/* <p>Application Open: </p> */}
          <a href='https://www.foundry10.org/artist-residency-program' className="f10-banner-link" target="_blank" rel="noopener noreferrer">Apply Now: Visual Artist Residency</a>
          <i className="fa-solid fa-arrow-right-long" id="bannerAlertArrow"></i>
        </div>
        <button className="close-button" onClick={handleBannerDismiss}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
  );
};

export default Banner;