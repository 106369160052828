import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SmartHeader from './smart-Header';
import SmartContent from './smart-Content';
import './smart.css';

export default class Smart extends Component {
  render() {
    return(
      <div id="smart-container">
        <Helmet>
          <title>Parent Educator Action Response (PEAR)</title>
          <meta
            name="title"
            content="Parent Educator Action Response (PEAR)"
          />
          <meta
            name="description"
            content="Foundry10 supports research at UW SMART Center to improve student outcomes by reducing mental health disparities in preschool."
          />
          <meta property="og:title" content="Parent Educator Action Response (PEAR)" />
          <meta
            property="og:description"
            content="Foundry10 supports research at UW SMART Center to improve student outcomes by reducing mental health disparities in preschool."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/parent-educator-action-response" />
          <link rel="canonical" href="https://www.foundry10.org/parent-educator-action-response" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Parent Educator Action Response (PEAR)",
                "description": "Foundry10 supports research at UW SMART Center to improve student outcomes by reducing mental health disparities in preschool.",
                "url": "https://www.foundry10.org/parent-educator-action-response"
              }
            `}
          </script>
        </Helmet>
        <SmartHeader />
        <SmartContent />
      </div>
    );
  }
}