import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import Grid from '@material-ui/core/Grid';
import StoryCard from '../components/storyCard';
//import ContentBlocks from '../../components/ContentBlocks/ContentBlocks';
// import GlobalFooter from '../components/base/GlobalFooter';
import NewFooter from '../../pages-other/NewFooter.js';

const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const segment_array = segment_str.split( '/' );
const lastItem = segment_array[segment_array.length - 1];
const last_segment = segment_array.pop();



const memberQuery = gql`
    query teamMemberQuery($slug: [String]) {
        entries(section: "teamMembers", slug: $slug) {
          title
          ... on teamMembers_default_Entry {
            teamMemberPosition
            teamMembersPronouns
            teamMemberImage {
              url
            }
            teamMemberBio
          }
        }
    }
`

const relatedContentQuery = gql`
    query relatedContentQuery($author: String) {
        entries(section: ["stories","resources","press"], search: $author) {
          postDate @formatDateTime (format: "M j, Y")
          title
          url
          author {
            fullName
          }
          ... on stories_default_Entry {
            blurb
            featuredImage {
              title
              url
            }
            featuredImageCaption
            categories {
              title
            }
            themes {
              title
            }
            interestAreas {
              title
            }
            researchExternalAuthors {
              ... on researchExternalAuthors_BlockType {
                researchExternalAuthorsAuthor
              }
            }
            interestAreaTeam {
              ... on interestAreaTeam_BlockType {
                interestAreaTeamMember {
                  title
                }
              }
            }
          }
          ... on resources_default_Entry {
            blurb
            featuredImage {
              title
              url
            }
            featuredImageCaption
            
            themes {
              title
            }
            interestAreas {
              title
            }
            resourceType{
              title
            }
            researchExternalAuthors {
              ... on researchExternalAuthors_BlockType {
                researchExternalAuthorsAuthor
              }
            }
            interestAreaTeam {
              ... on interestAreaTeam_BlockType {
                interestAreaTeamMember {
                  title
                }
              }
            }
          }
          ... on press_default_Entry {
            blurb
            featuredImage {
              title
              url
            }
            featuredImageCaption 
            
            themes {
              title
            }
            interestAreas {
              title
            }
            pressType {
              title
            }
            resourceType {
              title
            }
            researchExternalAuthors {
              ... on researchExternalAuthors_BlockType {
                researchExternalAuthorsAuthor
              }
            }
            interestAreaTeam {
              ... on interestAreaTeam_BlockType {
                interestAreaTeamMember {
                  title
                }
              }
            }
          }
        }
    }
` 

const TeamMember = (slug) => {
    const { data, loading, error } = useQuery(memberQuery, {
        variables: {slug}
    });
    if (loading) return null;
    if (error) return `Error! ${error}`;
    

    //Set Team Member
    const teamMember = data.entries[0];
    let teamImage = '/news-archive-spacer.png'
    if ( teamMember.teamMemberImage.length ) {
      teamImage = teamMember.teamMemberImage[0].url;
    }
    return (
      <section id="teamMember">
        <div class="container">
          <Grid container spacing={32}>
            <Grid item md={5} className="text-left">
            
              <img src={teamImage} alt={teamMember.teamMemberName} />
            </Grid>
            <Grid item md={7}>
              <div class="teamMemberTitle">
                <h2 className='text-xxl'>
                  {teamMember.title}
                </h2>
                {teamMember.teamMembersPronouns && (
                  <p className='text-half-step_bold'>
                    {teamMember.teamMembersPronouns}
                  </p>
                )}
              </div>
          {teamMember.teamMemberPosition && (
                 <p className='text-large'>{teamMember.teamMemberPosition}</p>
              )}
             
              <div
                className='teamMemberProfile'
                dangerouslySetInnerHTML={{
                  __html: teamMember.teamMemberBio,
                }}
              ></div>
            </Grid>
           
          </Grid>
            <RelatedContent author={teamMember.title}/>
        </div>
      </section>
    );
}

const RelatedContent = (author) => {
  const { data, loading, error } = useQuery(relatedContentQuery, {
      variables: { author : author.author}
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;
  return (
    <div>
    {data.entries.length == 0 ?<div></div>: <div className='recentPublications'>
            <h3 className='text-large_bold'>Related Content</h3>
          </div>}
    <section id="relatedEntries">
      <div class="container">
      {data.entries.map(
        (block, index) => {
        
          let props = {
            block: {block},
            orientation: 'wide'
          }
          return (
            <Grid item sm={12}>
              <StoryCard {...props} />
            </Grid>
          )
        }
      )}
      </div>
    </section></div>
  );
}

export default class TeamEntry extends Component {
  render() {
    return(
      <div className="teamEntryContainer">
        <TeamMember slug={last_segment} />
        {/* <GlobalFooter /> */}
        <NewFooter />
      </div>
    );
  }
}