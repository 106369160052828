import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './Research.css';
import axios from 'axios';
import ResearchTeam from './Research-Team';
import DocumentViewer from './DocumentViewer';
import NewFooter from '../../pages-other/NewFooter';

import Error404Page from '../../pages-other/NewSite404/newSite404';

export default class Research extends Component {
  constructor(props) {
    super(props)
    this.state = {
      research: {},
      allPeople: []
    };
  }

  componentDidMount() {
    const nameShort = this.props.props.match.params.name;
    axios.post('/research/getIndividResearch/'+nameShort, {
      data: nameShort
    }).then(data => {
      this.setState({
        research: data.data,
        allPeople: data.data.allPeople
      })
    }).catch(err => {
      console.log('err in /getResearch: ', err);
    })
  }

  render() {
    if (this.state.research) {
      console.log('What is this.state.research?', this.state.research);
      return (
        <div id="researchPost-main-container">
          <Helmet>
            <title>{this.state.research.name}</title>
            <meta
              name="title"
              content={this.state.research.name}
            />
            <meta
              name="description"
              content={this.state.research.brief}
            />
            <meta property="og:title" content={this.state.research.name} />
            <meta
              property="og:description"
              content={this.state.research.brief}
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://www.foundry10.org/research/${this.state.research.nameShort}`} />
            <link rel="canonical" href={`https://www.foundry10.org/research/${this.state.research.nameShort}`} />

            {/* Prevents search engine crawlers from indexing page */}
            <meta name="robots" content="noindex" />
            
            <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org",
                  "@type": "WebPage",
                  "name": "${this.state.research.name}",
                  "description": "${this.state.research.brief}",
                  "url": "https://www.foundry10.org/research/${this.state.research.nameShort}"
                }
              `}
            </script>
          </Helmet>
          {/* HEADER */}
          {/* <div class="researchPost-header-container" style={{backgroundImage: "url(" + `${this.state.research.headerImageUrl}` + ")"}}> */}
          <div class="researchPost-header-container" style={{backgroundImage: `url(${this.state.research.headerImageUrl})`}}>
            <div class="researchPost-header-content-container">
              <h1>{this.state.research.name}</h1>
            </div>
          </div>
          {/* BODY */}
          <div id="researchPost-main-content-container">
            {/* BRIEF & DOCUMENT */}
            <div id="researchPost-briefDoc-container">
              <div class="researchPost-briefDoc-content-container">
                <div class="researchPost-brief-container">
                  <div class="researchPost-brief-description-container">
                    <p>{this.state.research.brief}</p>
                  </div>
                </div>
                <div class="researchPost-document-container">
                  <p>Explore the research here!</p>
                  {this.state.research.documentUrl ? <DocumentViewer documentUrl={this.state.research.documentUrl}/> : null}
                </div>
              </div>
            </div>
            {/* DESCRIPTION */}
            <div id="researchPost-description-container">
              <div class="researchPost-description-content-container">
                <h4>{this.state.research.date}</h4>
                <p>{this.state.research.description}</p>
              </div>
            </div>
            {/* RESEARCH TEAM */}
            <ResearchTeam all={this.state.allPeople} />
            <NewFooter />
          </div>
        </div>

      )
    } else {
      console.log('Reached else condition')
      return (
          <Error404Page />
        )
    }
  }
}