import React, { useState } from 'react';
import './ResearchTopics-One.css';

function CareerConnectedLearningLifeSkillsOne() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-item">
                <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-image">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Career+Connected+Learning+and+Life+Skills/Research+Topics/IA+Team+Page+Icons_Teaching+Soft+Skills+in+Career+and+Technical+Education+1.png" alt="two youth classmates in cooking class"/>
                    </div>
                    <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-text">
                        <h2 className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-title-text">Teaching Soft Skills in Career and Technical Education (CTE)</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-content">
                    <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-description">
                        <p><a href="https://www.nber.org/reporter/2017number4/value-soft-skills-labor-market" target="_blank" rel="noopener noreferrer">According to economists and employers</a>, non-technical “soft skills” like communication, critical thinking, and collaboration are vital for success in every area of the 21st-century workforce, including careers in trades, like construction or culinary arts, that also require strong technical skill preparation. Yet, employers are finding that <a href="https://business.linkedin.com/content/dam/me/business/en-us/talent-solutions/resources/pdfs/global-talent-trends-2019.pdf" target="_blank" rel="noopener noreferrer">recent graduates are not meeting soft skills needs</a>, indicating a critical gap in career preparation for many young people today.</p>
                    </div>  
                    <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-descriptionTwo">
                        <h2>To address this gap, our current research explores soft skills awareness, understanding, and practice with a focus on Career and Technical Education (CTE) that answers key questions such as:</h2>
                        <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>Are CTE teachers and students familiar with soft skills?</p>
                        </div>
                        <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How do students learn about and practice soft skills in their CTE programs?</p>
                        </div>
                        <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What challenges to teaching soft skills do CTE teachers experience, and how might they be addressed?</p>
                        </div>
                    </div>
                    <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-description">
                        <p><a href="https://www.foundry10.org/stories/new-research-teaching-soft-skills-to-cte-students" target="_blank" rel="noopener noreferrer">Read this blog</a> to learn more about foundry10's preliminary research related to this topic.</p>
                    </div>  
                    {/* <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-descriptionThree">
                        <h2>VR/AR</h2>
                        <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How are young people using AR/VR as a tool to participate, create, and innovate?</p>
                        </div>
                        <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can VR be used as a training tool for soft skills (e.g., creative thinking skills, decision-making ability, interpersonal communication skills)? How do young people implement soft skills experienced through VR in non-virtual settings?</p>
                        </div>
                        <div className="careerConnectedLearningLifeSkills-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>We plan to use a variety of approaches to answer these questions such as: usability studies (measuring if the emerging technology is easy to use), feasibility studies (measuring if the emerging technology is feasible to use as intended within the classroom), and correlational studies (what relationship might exist between VR and soft skills attainment).</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default CareerConnectedLearningLifeSkillsOne;
