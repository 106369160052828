import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsSunburst from 'highcharts/modules/sunburst';
import SunburstData from './sunburstData';
import './sunburst.css'
import NewFooter from '../../../../pages-other/NewFooter'

highchartsSunburst(Highcharts);

export default class HealthEdSunburst extends Component {
  constructor(props) {
    super(props);

    this.state = {

        detailsData: {
            title: 'Click Around to Learn About Our Health Ed Initiatives',
            description: '',
            url: '/health-ed-sunburst-demo',
            state: 'sunburst-initial'
        },

        chartOptions: {
            colors: ['transparent','#939598', '#0E2528', '#01646F'],
            chart: {
                type: 'sunburst',
                height: '100%',
            },
            title: {
                text: ''
            },
            // subtitle: {
            //     text: 'Optional Subtitle'
            // },
            tooltip: {
                pointFormat: `{point.name}`,
                outside: true,
                backgroundColor: 'white'
            },
            accessibility: {
                // Accessibility options for a series.  https://api.highcharts.com/highcharts/series.sunburst.accessibility
            },
            series: [{
                type: 'sunburst',
                data: SunburstData,
                name: 'Health Education Initiatives',
                dataLabels: {
                    // allowOverlap: false,
                    format: '{point.displayName}{point.icon}' ,
                    // descripton: 'A description of the series to add to the screen reader information about the series.',
                    // padding: '10px',
                    // textPath: {
                    //     enabled: false,
                    //     attributes: {
                    //         startOffset: '10%',
                    //         textAnchor: 'start'
                    //     },
                    // },
                    verticalAlign: 'top',
                    useHTML: true,

                },
                allowTraversingTree: false,
                allowPointSelect: true,
                states: {
                    select: {
                        color: '#d14e1d'
                    }
                },
                point: {
                    events: {
                        click: this.setNewDetailsData.bind(this)
                    }
                },
                label: {
                    format: '',
                },
                levels: 
                    [{
                        level: 1,
                        colorByPoint: true,
                        levelSize: {
                            unit: 'weight',
                            value: 1
                        }
                    },
                    {
                        level: 2,
                        // colorVariation: {
                        //     key: 'brightness',
                        //     to: -0.5
                        // }
                        // levelSize: {
                        //     unit: 'weight',
                        //     value: 2
                        // }
                    },
                    {
                        level: 3,
                        // colorVariation: {
                            // key: 'brightness',
                            // to: .5
                        // }

                        // color: '#e77c53'
                    },
                    {
                        level: 4,
                        // color: '#00464f'
                    }],
            }]
        }
    };
  }

  setNewDetailsData = (e) => {
    // console.log(this.state.chartOptions.series[0].point);
    // console.log('E...', e.point.options),
    this.setState({
        detailsData: {
            title: e.point.options.name,
            description: e.point.options.detailContent,
            descriptionCont: e.point.options.detailContentCont,
            linkText: e.point.options.linkText,
            url: e.point.options.url,
            state: 'sunburst-clicked'
        },

    })
}


  render() {
    const { chartOptions, detailsData } = this.state;


    return (
    <div>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="sunburst-page-header">
            <div className="sunburst-page-hero-img">
                <h1>2022 Health Ed Team Project Highlights</h1>
            </div>
            <h2>Our Heath Ed team worked on a variety of projects in 2022. These are the highlights of the philanthropy, programming, and research they engaged in to support youth health and wellbeing across the year.</h2>
        </div>
        <div className="sunburst-chart-container">
            <div className="sunburst-chart">
                <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                />
            </div>
            <div className={detailsData.state}>
                <div className="sunburst-more-info">
                    <h1>{detailsData.title}</h1>
                    <p>{detailsData.description}</p>
                    <p>{detailsData.descriptionCont}</p>
                    <a href={detailsData.url} target="_blank" rel="noopener noreferrer">{detailsData.linkText}</a>
                </div>
            </div>
        </div>
        <NewFooter />
    </div>
    )
  }
}
